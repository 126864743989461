import { NgModule, type ModuleWithProviders } from '@angular/core';

import type { ProvideTranslationsConfig } from './interfaces/provide-translations.interface';
import { CosTranslatePipe } from './overrides';
import { GetTranslationContextPipe } from './pipes/translation-context.pipe';
import { provideTranslations } from './utils';

@NgModule({
  imports: [CosTranslatePipe, GetTranslationContextPipe],
  exports: [CosTranslatePipe, GetTranslationContextPipe],
})
export class CosmosUtilTranslationsModule {
  static forRoot(
    provideTranslationsConfig: ProvideTranslationsConfig
  ): ModuleWithProviders<CosmosUtilTranslationsModule> {
    return {
      ngModule: CosmosUtilTranslationsModule,
      providers: [provideTranslations(provideTranslationsConfig)],
    };
  }
}
