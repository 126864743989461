// THIS FILE IS BEING UPDATED USING GENERATORS. DO NOT EDIT MANUALLY.
//
// Find out more about the usage of i18n generators here libs/cosmos/util-translations/README.md

export const SCOPES_PER_APP = {
  admin: ['cosmos', 'espMisc', 'espCommon', 'smartlink', 'supplierAnalytics'],
  encore: [
    'espCommon',
    'espDecorators',
    'espProducts',
    'espPresentations',
    'espProjects',
    'espSettings',
    'espSuppliers',
    'espMisc',
    'espWebsites',
    'espProofs',
    'cosmos',
    'espDecorations',
    'espOrders',
    'espCollections',
    'espCollaborators',
    'espCompanies',
    'espLookup',
    'espActivities',
    'espCrm',
    'espSearch',
    'espProductOptionsAssistant',
    'espContacts',
    'espTasks',
    'espCrm',
    'espNotes',
    'espSearch',
    'espContacts',
    'espAuth',
    'espPreferredSuppliers',
    'smartlink',
    'websiteModules',
    'espHome',
    'espCustomProducts',
    'pim',
    'espEmails',
    'espNotifications',
    'espDesignStudio',
    'espStores',
    'espPayments',
    'espIntegrations',
    'espRouting',
    'espMySearches',
    'espDashboard',
  ],
  'customer-portal': [
    'espCommon',
    'espProofs',
    'cosmos',
    'customerPortalPresentations',
    'customerPortalCommon',
    'espPresentations',
    'customerPortalCommon',
    'customerPortalProofs',
    'customerPortalQuotes',
    'customerPortalOrders',
    'customerPortalInvoices',
    'espOrders',
    'customerPortalAuth',
    'smartlink',
    'websiteModules',
    'checkout',
    'espPayments',
    'espNotes',
  ],
  storefront: [
    'storefront',
    'cosmos',
    'espOrders',
    'espSearch',
    'espCommon',
    'espProducts',
    'espPresentations',
    'customerPortalPresentations',
    'customerPortalCommon',
    'smartlink',
    'espProofs',
    'websiteModules',
    'checkout',
    'espPayments',
    'espDesignStudio',
  ],
  'ad-content': ['cosmos', 'espProducts', 'smartlink'],
  'esp-mobile': [
    'espCommon',
    'cosmos',
    'espAuth',
    'espSearch',
    'espMobileAuth',
    'espMobileProducts',
    'espProducts',
    'smartlink',
    'espMobileSuppliers',
    'espSuppliers',
    'espMobileCollections',
    'espMobileHome',
    'espMobileMisc',
    'espCollaborators',
    'espMobileNotifications',
    'espNotifications',
    'espMobileDecorators',
    'espMobileSettings',
    'espLookup',
    'espCrm',
    'espMobileCrm',
    'espContacts',
    'espMobileCompanies',
    'espMobileCollaborators',
    'espMobileContacts',
    'espCompanies',
    'espNotes',
    'espSettings',
    'espTasks',
    'espMobileSearch',
  ],
  supplier: [
    'supplierHome',
    'espCommon',
    'espAuth',
    'cosmos',
    'espSearch',
    'espMisc',
    'supplierNavigation',
    'supplierSearch',
    'supplierCreditReports',
    'supplierProducts',
    'smartlink',
    'supplierDistributors',
    'supplierShared',
    'espProducts',
    'espSettings',
    'espLookup',
    'espCompanies',
    'supplierSettings',
    'espCrm',
    'espSuppliers',
    'supplierAnalytics',
  ],
  checkout: [
    'storefront',
    'cosmos',
    'espOrders',
    'espSearch',
    'espCommon',
    'espProducts',
    'espPresentations',
    'customerPortalPresentations',
    'customerPortalCommon',
    'smartlink',
    'espProofs',
    'websiteModules',
    'checkout',
    'espPayments',
  ],
  'storefront-mobile': [
    'cosmos',
    'espMobileMisc',
    'storefrontMobileProducts',
    'smartlink',
    'storefrontMobileStores',
    'espProducts',
    'storefrontMobileHome',
    'storefrontMobileCollections',
    'espCommon',
    'storefrontMobileMisc',
    'storefrontMobileCart',
    'espPresentations',
    'storefrontMobileCatalog',
  ],
  ppm: [
    'cosmos',
    'espMisc',
    'espCommon',
    'ppm',
    'smartlink',
    'espSearch',
    'espProducts',
    'espSuppliers',
  ],
  'media-manager': ['cosmos', 'espCommon'],
  'ppm-portal': ['espCommon', 'cosmos'],
  'asi-show-portal': ['cosmos', 'espProducts', 'espSuppliers', 'espCommon'],
  'supplier-payments': ['cosmos', 'espCommon'],
};
