import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import type { Translation, TranslocoLoader } from '@jsverse/transloco';
import { map, Observable, of, share, tap } from 'rxjs';

import { assertDefined } from '@cosmos/util-common';
import { REQUEST, TRANSLATIONS } from '@cosmos/util-express';

import { higherOrderLanguageScopes, Languages } from '../consts';
import { languageScopeToPath } from '../utils';

/**
 * Loads combined by scope translations (e.g. "esp/en-us.json").
 * Those are being used in production builds.
 */
@Injectable({ providedIn: 'root' })
export class CombinedTranslationsHttpLoader implements TranslocoLoader {
  /** The array is sorted in order to get longer matches first (e.g. esp vs espMobile) */
  private readonly _higherOrderLanguageScopesSorted = [
    ...higherOrderLanguageScopes.values(),
  ].sort((a, b) => b.length - a.length);

  private readonly _cache = new Map<string, Translation>();
  private readonly _cachedTranslationRequests: Record<
    string,
    Observable<Translation>
  > = Object.create(null);

  private readonly _http = inject(HttpClient);
  private readonly _request = ngServerMode ? inject(REQUEST) : null;
  private readonly _translations = ngServerMode
    ? inject(TRANSLATIONS, { optional: true })
    : null;

  getTranslation(lang: Languages): Observable<Translation> {
    const { path, innerScope } = this._getLangPath(lang);
    const cache = this._cache.get(path);
    if (cache) {
      return of(innerScope ? cache[innerScope] : cache);
    }
    return this._getCachedTranslationRequest(path).pipe(
      tap((v) => this._cache.set(path, v)),
      map((v) => (innerScope ? v[innerScope] : v))
    );
  }

  private _getCachedTranslationRequest(path: string): Observable<Translation> {
    // When we're in the browser, we only need the relative URL because the
    // browser automatically prepends the current origin (e.g., `http://localhost:4200`)
    // to form the full URL.
    const relativeUrl = `/assets/i18n/${path}.json`;

    let url = relativeUrl;

    if (ngServerMode) {
      // If we have translations resolved from the `dist/assets/i18n` folder,
      // we can return them prematurely from the cache; there's no need to make an HTTP request.
      if (this._translations?.has(relativeUrl)) {
        return of(this._translations.get(relativeUrl)!);
      }

      const request = this._request!;
      ngDevMode && assertDefined(request, 'Express request should be defined.');
      const protocol = request.secure ? 'https' : 'http';
      const host = request.get('host');
      // In a Node.js server, the environment does not have a "current origin"
      // like browsers do. As a result:
      // A relative URL like `/assets/i18n/en-us.json` won't work because Node.js
      // cannot resolve it without context.
      // We need to provide a full absolute URL
      // (e.g., `http://localhost:4200/assets/i18n/en-us.json`) for the fetch request to succeed.
      url = new URL(url, `${protocol}://${host}`).toString();
    }

    return (
      this._cachedTranslationRequests[path] ||
      (this._cachedTranslationRequests[path] = this._http
        .get<Translation>(url, {
          headers: new HttpHeaders({ skipAuthHeader: 'true' }),
        })
        .pipe(share()))
    );
  }

  private _getLangPath(lang: Languages): { path: string; innerScope?: string } {
    const langPath = languageScopeToPath(lang);
    // For scoped languages values here will look like "espCommon/en-us".
    // Since we need only the scope value, using "words" util to return "esp/en-us"
    const scope = this._higherOrderLanguageScopesSorted.find((v) =>
      langPath.startsWith(v)
    );
    const langValue = langPath.split('/').pop();
    return {
      path: [scope, langValue].filter(Boolean).join('/'),
      innerScope: langPath
        // customer-portal-common/en-us => common/en-us
        .replace(new RegExp(`^${scope}-?`), '')
        // common/en-us => common
        .replace(new RegExp(`/${langValue}$`), ''),
    };
  }
}
