import {
  APP_INITIALIZER,
  makeEnvironmentProviders,
  type EnvironmentProviders,
  type FactoryProvider,
} from '@angular/core';
import {
  DefaultFallbackStrategy,
  DefaultInterceptor,
  DefaultMissingHandler,
  provideTranslocoFallbackStrategy,
  provideTranslocoInterceptor,
  provideTranslocoMissingHandler,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_TRANSPILER,
  translocoConfig,
  // eslint-disable-next-line no-restricted-syntax
  TranslocoService,
} from '@jsverse/transloco';

import { injectNavigator } from '@cosmos/util-common';
import { getEnumEntries } from '@cosmos/util-enum-helpers';

import { Languages, LanguageScope, SCOPES_PER_APP } from '../consts';
import type { CosmosTranslocoConfig } from '../interfaces/config.interface';
import type { ProvideTranslationsConfig } from '../interfaces/provide-translations.interface';
import {
  CombinedTranslationsHttpLoader,
  CosmosTranslocoService,
  IntlMessageformatTranspiler,
  PRELOAD_LANGS_PARAMS,
  TranslationsHttpLoader,
  TranslocoPersistLangService,
  TranslocoPreloadLangsService,
} from '../services';

function noop() {}

/**
 * Generates a collection of Angular providers and configuration necessary for translation functionality.
 * This function can be utilized in a standalone application instead of importing the `CosmosUtilTranslationsModule.forRoot()` module.
 */
export const provideTranslations = (
  provideTranslationsConfig: ProvideTranslationsConfig
): EnvironmentProviders => {
  const initPreloadService: FactoryProvider = {
    provide: APP_INITIALIZER,
    useFactory: () => noop,
    multi: true,
    deps: [TranslocoPreloadLangsService],
  };
  if (provideTranslationsConfig.setupPersistLangService) {
    initPreloadService.deps!.push(TranslocoPersistLangService);
  }

  const availableLangs: Languages[] =
    provideTranslationsConfig.supportedLanguages ??
    getEnumEntries(Languages).map((l) => l.value);

  const configFactory = (): CosmosTranslocoConfig => {
    const navigator = injectNavigator();

    const defaultLang =
      availableLangs.find(
        (language) => language === navigator.language?.toLowerCase()
      ) ?? Languages.EnUs;

    const config: CosmosTranslocoConfig = {
      availableScopes: (provideTranslationsConfig.appName
        ? SCOPES_PER_APP[provideTranslationsConfig.appName]
        : getEnumEntries(LanguageScope).map((l) => l.value)) as LanguageScope[],
      availableLangs,
      defaultLang,
      reRenderOnLangChange: true,
      prodMode: provideTranslationsConfig.production,
    };
    return translocoConfig(config) as CosmosTranslocoConfig;
  };

  return makeEnvironmentProviders([
    provideTranslocoMissingHandler(DefaultMissingHandler),
    provideTranslocoInterceptor(DefaultInterceptor),
    provideTranslocoFallbackStrategy(DefaultFallbackStrategy),
    initPreloadService,
    {
      provide: PRELOAD_LANGS_PARAMS,
      useValue: provideTranslationsConfig.preloadLang || {},
    },
    {
      provide: TRANSLOCO_CONFIG,
      useFactory: configFactory,
    },
    {
      provide: TRANSLOCO_LOADER,
      useExisting: /* mergeTranslations */ global_isServeMode
        ? TranslationsHttpLoader
        : CombinedTranslationsHttpLoader,
    },
    {
      provide: TranslocoService,
      useExisting: CosmosTranslocoService,
    },
    {
      provide: TRANSLOCO_TRANSPILER,
      useClass: IntlMessageformatTranspiler,
    },
  ]);
};
