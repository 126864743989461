import { Injectable } from '@angular/core';

import { injectWindow } from '@cosmos/util-common';

import { BaseStorageService } from './abstract-storage.service';
import { MemoryStorage } from './memory-storage';

@Injectable({ providedIn: 'root' })
export class LocalStorageService extends BaseStorageService {
  constructor() {
    try {
      super(ngServerMode ? new MemoryStorage() : injectWindow().localStorage);
    } catch (error) {
      ngDevMode &&
        console.warn(
          'Failed to retrieve the instance of "localStorage", falling back to "MemoryStorage"',
          error
        );
      super(new MemoryStorage());
    }
  }
}
