import {
  inject,
  Pipe,
  type OnDestroy,
  type PipeTransform,
} from '@angular/core';
import {
  // eslint-disable-next-line no-restricted-syntax
  TranslocoPipe,
  type HashMap,
} from '@jsverse/transloco';

import type { Nullable } from '@cosmos/types-common';

import { CosmosTranslocoService } from '../services';

@Pipe({
  name: 'cosTranslate',
  // eslint-disable-next-line @angular-eslint/no-pipe-impure
  pure: false,
})
export class CosTranslatePipe
  extends TranslocoPipe
  implements PipeTransform, OnDestroy
{
  private readonly _seenKeys = new Set<Nullable<string>>();

  private readonly _translocoService = inject(CosmosTranslocoService);

  override transform(
    key: Nullable<string>,
    params?: HashMap | undefined,
    inlineLang?: string | undefined
  ): string {
    this._addScopeFromKey(key);
    return super.transform(key, params, inlineLang);
  }

  private _addScopeFromKey(key: Nullable<string>): void {
    if (!key || this._seenKeys.has(key)) {
      return;
    }
    this._seenKeys.add(key);
    const scope = this._translocoService._deduceScopeFromKey(key);
    if (scope) {
      this._assertPrivatePropertiesExist();
      // @ts-expect-error intentionally accessing a private property
      super.providerScope = [scope]
        // @ts-expect-error intentionally accessing a private property
        .concat(super.providerScope)
        .filter(Boolean)
        .flat(Infinity);
    }
  }

  private _assertPrivatePropertiesExist() {
    // eslint-disable-next-line no-prototype-builtins
    if (ngDevMode && !super.hasOwnProperty('providerScope')) {
      throw Error(
        'It looks like "providerScope" property has been renamed or removed'
      );
    }
  }
}
