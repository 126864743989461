import { inject, Injectable } from '@angular/core';
import {
  // eslint-disable-next-line no-restricted-syntax
  TranslocoService,
  type HashMap,
  type TranslateParams,
} from '@jsverse/transloco';
import { delayWhen, forkJoin, map, Observable, switchMap, take } from 'rxjs';

import { dayjs } from '@cosmos/util-dayjs';

import './../locales/import-locales';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { LocalStorageService } from '@cosmos/data-access-storage';

import type { Languages, LanguageScope } from '../consts';
import type { CosmosTranslocoConfig } from '../interfaces/config.interface';
import { toLangValue } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class CosmosTranslocoService extends TranslocoService {
  private readonly _localStorageService = inject(LocalStorageService);

  private readonly _additionalScopesSet = new Set(
    (this.config as CosmosTranslocoConfig).availableScopes
  );

  override translate<T = string>(
    key: TranslateParams,
    params?: HashMap,
    lang?: string
  ): T {
    try {
      if (global_isBrowser && typeof key === 'string') {
        const showTranslations =
          this._localStorageService.getItem('showTranslations');

        if (showTranslations === true) {
          return ('$$$' + super.translate(key, params, lang)) as T;
        }
      }

      return super.translate(key, params, lang);
    } catch (error) {
      ngDevMode && console.error(error);
      return key as T;
    }
  }

  /**
   * Method that returns the same Observable as `langChanges$`.
   * It accepts the list of scopes and makes sure they're preloaded before emitting.
   */
  getLangChanges$(
    translocoScopes?: LanguageScope[] | null
  ): Observable<Languages> {
    const langChanges$ = this.langChanges$ as Observable<Languages>;

    if (!translocoScopes?.length) {
      return langChanges$.pipe(
        delayWhen(() => super._loadDependencies(this.getActiveLang()))
      );
    }

    return langChanges$.pipe(
      switchMap((lang) => {
        const paths = translocoScopes.map((scope) =>
          toLangValue({ lang, scope })
        );

        return forkJoin(paths.map((p) => super._loadDependencies(p))).pipe(
          map(() => lang)
        );
      })
    );
  }

  /**
   * Returns an observable that when subscribed loads the translation file and provided scopes
   * and emits the translation of the given key
   */
  selectTranslateWithScope(
    translocoScopes: LanguageScope[],
    key: string,
    params?: HashMap
  ): Observable<string> {
    return this.getLangChanges$(translocoScopes).pipe(
      map(() => this.translate(key, params))
    );
  }

  override setActiveLang(lang: string): this {
    dayjs.locale(lang);
    return super.setActiveLang(lang);
  }

  override getActiveLang(): Languages {
    return super.getActiveLang() as Languages;
  }

  ensureScopesAreLoaded$(
    translocoScopes: LanguageScope[]
  ): Observable<Languages> {
    return this.getLangChanges$(translocoScopes).pipe(take(1));
  }

  _deduceScopeFromKey(key: string | undefined): LanguageScope | undefined {
    const scope = key?.split('.').shift() as LanguageScope;
    if (scope && this._additionalScopesSet.has(scope)) {
      return scope;
    }
    return;
  }
}
